<template>
  <div>
    <div class="we-flex-col we-justify-content-center margin20b">
      <van-image width="60" height="60" round :src="imgUrl" class="imgcenter" />
      <p class="we-font-size-xxlo text-align-c">{{realName}}</p>
    </div>
    <div >
      <div v-if="change">
        <van-cell-group>
          <van-cell title="安全手机"  icon="bulb-o">
            <template #default>{{phone}}</template>
          </van-cell>
        </van-cell-group>
        <div style="margin:40px 16px 20px;">
        <van-button block type="primary" native-type="button" @click="change = false" color="linear-gradient(to right, #009C30, #07BF3F)"  round>更换手机号</van-button>
      </div>
      </div>
      
      <div v-else>
        <van-form @submit="onSubmit" :show-error="false" :validate-first="true">
          <van-field
            v-model="phone"
            type="tel"
            name="phone"
            label="手机号"
            placeholder="请填写手机号"
            :rules="[{ required: true,pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,message: '请填写正确的手机号' }]"
          />
          <van-field
            v-model="verificationCode"
            center
            clearable
            name="verificationCode"
            label="短信验证码"
            placeholder="请填写短信验证码"
            :rules="[{ required: true,pattern:/^\d{4}$/,message: '验证码为4位数字' }]"
            maxlength="4"
           >
            <template #button>
              <span v-if="showcut">
                <van-button size="small" type="primary" native-type="button" @click="getCode" round>发送验证码</van-button>
              </span>
              <span v-else class="cutdown">
                <van-count-down :time="time" format="ss" @finish="finish" />秒后重新发送
              </span>
            </template>
          </van-field>

          <div style="margin:40px 16px 20px;">
            <van-button block type="primary" native-type="submit" color="linear-gradient(to right, #009C30, #07BF3F)"  round>保存</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Field, Image as VanImage, Button, Form,CountDown } from "vant";
import { getUserinfo, updatePhone } from "@api/user";
import { userSend } from "@api/verification";

export default {
  name: "security",
  data() {
    return {
      imgUrl: "",
      realName: "",
      phone: "",
      verificationCode: "",
      time: 60000,
      showcut: true,
      change:true
    };
  },

  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Form.name]: Form,
    [CountDown.name]: CountDown,
  },

  
  methods: {
    //获取验证码
    getCode() {
      if (this.phone == "") {
        this.$toast("请先输入手机号");
      } else {
        this.showcut = false;
        userSend({ phone: this.phone }).then((res) => {
          if (res.respCode == 200) {
            this.$toast("发送成功");
          } else {
            this.$toast(res.respMsg);
          }
        });
      }
    },
    //倒计时结束
    finish() {
      this.showcut = true;
    },
    //提交修改
    onSubmit(values) {
      // console.log("submit", values);
      updatePhone(values).then((res) => {
        if (res.respCode == 200) {
          this.$toast("修改成功");
          this.change = true
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
    //获取个人信息
    ajax_getUserInfo() {
      getUserinfo().then((res) => {
        if (res.respCode == 200) {
          this.imgUrl =
            res.result.headImage === null
              ? "https://img.yzcdn.cn/vant/cat.jpeg"
              : res.result.headImage;
          this.realName = res.result.realName;
          this.phone = res.result.phone;
        } else {
          this.$toast(res.respMsg);
        }
      });
    },
  },
  mounted() {
    this.ajax_getUserInfo();
  },
};
</script>
<style lang="less" scope>
.imgcenter {
  margin: 20px auto 0;
}
.position {
  position: absolute;
  bottom: 10px;
  // width: 100%;
  font-size: 12px;
  color: #999;
}
.cutdown {
  font-size: 12px;
  color: #999;
}
.van-count-down {
  display: inline;
}
</style>